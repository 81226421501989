<template>
  <div
    class="uni-timeline uni-timeline_size_2"
    :class="{
      active_1: getBattle.state === 1,
      active_2: getBattle.state === 2,
    }"
  >
    <button class="uni-timeline__item uni-timeline__item_type_inline">
      <div class="uni-timeline__title">Сбор заявок на участие:</div>
      <div class="uni-timeline__date">
        {{ getBattle.startDay | formatBeforeDateBegin }}-{{
          getBattle.startDay | formatBeforeDateEnd
        }}
      </div>
    </button>
    <button class="uni-timeline__item uni-timeline__item_type_inline">
      <div class="uni-timeline__title">Поединок:</div>
      <div class="uni-timeline__date">
        {{ getBattle.startDay | formatBattleDateBegin }}-{{
          getBattle.startDay | formatBattleDateEnd
        }}
      </div>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { format, addDays } from "date-fns";
import { ru } from "date-fns/locale";

export default {
  name: "BattleTimeline",
  filters: {
    formatBeforeDateBegin(date) {
      if (!date) return "";
      return format(new Date(date), "dd.MM", {
        locale: ru,
      });
    },
    formatBeforeDateEnd(date) {
      if (!date) return "";
      return format(addDays(new Date(date), 6), "dd.MM.yyyy", {
        locale: ru,
      });
    },
    formatBattleDateBegin(date) {
      if (!date) return "";
      return format(addDays(new Date(date), 7), "dd.MM", {
        locale: ru,
      });
    },
    formatBattleDateEnd(date) {
      if (!date) return "";
      return format(addDays(new Date(date), 13), "dd.MM.yyyy", {
        locale: ru,
      });
    },
  },
  created() {
    this.BATTLE_FETCH();
  },
  computed: {
    ...mapGetters(["getBattle", "getBattleStatus"]),
  },
  methods: {
    ...mapActions(["BATTLE_FETCH"]),
  },
};
</script>
